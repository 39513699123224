import React from 'react'
import GradientBlock, {
  type GradientBlockProps
} from '~/components/GradientBlock'
import { cn } from '~/utils/cn'
import { useResizeObserver } from '@zoi/react-hooks'

export type MarqueeProps = PropsWithClassName<{
  play?: boolean
  isPausedOnHover?: boolean
  pauseOnClick?: boolean
  speed?: number
  gradientColor?: GradientBlockProps['color']
  children?: React.ReactNode
}>

const Marquee = ({
  isPausedOnHover = false,
  speed = 50,
  gradientColor = undefined,
  className,
  children = undefined
}: MarqueeProps) => {
  const [state, setState] = React.useState({
    parentCount: 1,
    marqueeWidth: 0
  })
  const rootRef = React.useRef<HTMLDivElement>(null)
  const containerRef = rootRef
  const marqueeRef = React.useRef<HTMLDivElement>(null)

  const calculateWidth = () => {
    if (marqueeRef.current && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width
      const newMarqueeWidth = marqueeRef.current.getBoundingClientRect().width

      setState({
        marqueeWidth: newMarqueeWidth,
        parentCount:
          newMarqueeWidth < containerWidth
            ? Math.ceil(containerWidth / newMarqueeWidth)
            : 1
      })
    }
  }

  useResizeObserver({
    target: containerRef,
    callback: calculateWidth
  })

  const multiplyChildren = (newMultiplier: number) => {
    return [...Array(newMultiplier)].map(() => {
      return children
    })
  }

  const duration = (state.marqueeWidth * state.parentCount) / speed
  const classNameParent =
    'flex items-center z-1 animate-[marquee_var(--duration)_linear_infinite] shrink-0 grow-0 [animation-play-state:var(--pause-on-hover)] motion-reduce:animate-none'

  return (
    <div
      ref={containerRef}
      style={
        {
          '--duration': `${duration}s`,
          '--pause-on-hover': 'running'
        } as React.CSSProperties
      }
      className={cn(
        'relative flex w-full overflow-x-hidden',
        {
          '[&:hover]:[--pause-on-hover:paused!important]': isPausedOnHover
        },
        className
      )}
    >
      {gradientColor ? (
        <GradientBlock position="left" color={gradientColor} />
      ) : null}
      <div className={classNameParent}>
        <div className="flex flex-none items-center" ref={marqueeRef}>
          {React.Children.map(children, (child) => {
            return <div>{child}</div>
          })}
        </div>
        {multiplyChildren(state.parentCount - 1)}
      </div>
      <div className={classNameParent}>
        {multiplyChildren(state.parentCount)}
      </div>
      {gradientColor ? (
        <GradientBlock position="right" color={gradientColor} />
      ) : null}
    </div>
  )
}

export default Marquee
