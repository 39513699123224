import { cn } from '~/utils/cn'

export type GradientBlockProps = {
  position: 'left' | 'right'
  color?: 'white' | 'black'
}

const GradientBlock = ({ position, color = 'white' }: GradientBlockProps) => {
  return (
    <div
      className={cn(
        'absolute inset-y-0 z-[2] h-full w-[10rem] to-transparent max-sm:hidden',
        {
          'left-0 bg-gradient-to-r': position === 'left',
          'right-0 bg-gradient-to-l': position === 'right',
          'from-black': color === 'black',
          'from-white': color === 'white'
        }
      )}
    />
  )
}

export default GradientBlock
